<template>
  <div class="inward-box">
    <div class="warpper">
      <header>
        <div class="inward-info-box">
          <div class="iw-title">
            {{ $t("Funds.Inwardrem.AddUsdToYourAccount") }}
          </div>
          <div class="iw-amt-title">
            {{ $t("Funds.Inwardrem.YourFundAccount") }}
          </div>
          <div class="iw-amt-box">
            <div class="amt-item" style="padding-left: 0">
              <div class="amt-title">
                {{ $t("Funds.Inwardrem.AccountName") }}
              </div>
              <div class="amt-info">{{ funds_data.account_name }}</div>
            </div>
            <div class="amt-item">
              <div class="amt-title">
                {{ $t("Funds.Inwardrem.AccountNumber") }}
              </div>
              <div class="amt-info">{{ funds_data.account_number }}</div>
            </div>
            <div class="amt-item">
              <div class="amt-title">{{ $t("Funds.Inwardrem.BankName") }}</div>
              <div class="amt-info">{{ funds_data.bank_name }}</div>
            </div>
          </div>
          <div class="iw-dec">
            {{ $t("Funds.Inwardrem.Dec") }}
          </div>
          <div>
            <el-alert show-icon type="warning" :title="$t('Funds.Inwardrem.HuiRuTip')"></el-alert>
          </div>
          <div style="margin-top:10px">
            <el-alert show-icon type="warning" :title="$t('Funds.Inwardrem.HuiRuTip2')"></el-alert>
          </div>
        </div>
      </header>
      <main>
        <div class="main-box">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>{{ $t("Funds.Inwardrem.AccountInformation") }}</span>
            </div>
            <div class="box">
              <div class="main-item">
                <div>
                  <div class="m-item-key">
                    {{ $t("Funds.Inwardrem.BankName") }}
                  </div>
                  <div class="m-item-val">DBS Bank (Hong Kong) Limited</div>
                </div>
                <div>
                  <i @click="getCopy('DBS Bank (Hong Kong) Limited')" style="color: #00bea4;cursor: pointer" class="el-icon-document-copy"></i>
                </div>
              </div>
              <div class="main-item">
                <div>
                  <div class="m-item-key">
                    {{ $t("Funds.Inwardrem.BankCR") }}
                  </div>
                  <div class="m-item-val">中國香港</div>
                </div>
                <div>
                  <i @click="getCopy('中國香港')" style="color: #00bea4;cursor: pointer" class="el-icon-document-copy"></i>
                </div>
              </div>
              <div class="main-item">
                <div>
                  <div class="m-item-key">
                    {{ $t("Funds.Inwardrem.Address") }}
                  </div>
                  <!-- <div class="m-item-val">ROOM 1203, 12/F TUNG CHUN COMMERCIAL CENTRE 438 SHANGHAI STREET, KOWLOON HONG KONG</div> -->
                  <div class="m-item-val">G/F, The Center,99 Queen's Road Central, Central, Hong Kong</div>
                </div>
                <div>
                  <i @click="getCopy(tsAddr)" style="color: #00bea4;cursor: pointer" class="el-icon-document-copy"></i>
                </div>
              </div>
              <div class="main-item">
                <div>
                  <div class="m-item-key">
                    {{ $t("Funds.Inwardrem.AccountName") }}
                  </div>
                  <div class="m-item-val">Fu Tong E-commerce Services Limited</div>
                </div>
                <div>
                  <i @click="getCopy('Fu Tong E-commerce Services Limited')" style="color: #00bea4;cursor: pointer" class="el-icon-document-copy"></i>
                </div>
              </div>
              <div class="main-item">
                <div>
                  <div class="m-item-key">
                    {{ $t("Funds.Inwardrem.AccountNumber") }}
                  </div>
                  <!-- <div class="m-item-val">382554102632701</div> -->
                  <div class="m-item-val">001618063</div>
                </div>
                <div>
                  <i @click="getCopy('001618063')" style="color: #00bea4;cursor: pointer" class="el-icon-document-copy"></i>
                </div>
              </div>
              <div class="main-item">
                <div>
                  <div class="m-item-key">
                    {{ $t("Funds.Inwardrem.BankCode") }}
                  </div>
                  <div class="m-item-val">016</div>
                </div>
                <div>
                  <i @click="getCopy('382')" style="color: #00bea4;cursor: pointer" class="el-icon-document-copy"></i>
                </div>
              </div>
              <!-- <div class="main-item">
                <div>
                  <div class="m-item-key">{{ $t('Funds.Inwardrem.BranchCode') }}</div>
                  <div class="m-item-val">000</div>
                </div>
                <div>
                  <i style="color: #00bea4" class="el-icon-document-copy"></i>
                </div>
              </div> -->
              <div class="main-item">
                <div>
                  <div class="m-item-key">Swift Code</div>
                  <div class="m-item-val">DHBKHKHH</div>
                </div>
                <div>
                  <i @click="getCopy('DHBKHKHH')" style="color: #00bea4;cursor: pointer" class="el-icon-document-copy"></i>
                </div>
              </div>
              <div>
                <!-- <el-button
                  @click="showSubRetPf"
                  style="background: #00bea4; color: #fff"
                  >{{ $t("Funds.Inwardrem.SubmitRemittanceProof") }}</el-button
                > -->
                <button @click="showSubRetPf" class="btn custer sbtn" type="button">{{ $t("Funds.Inwardrem.SubmitRemittanceProof") }}</button>
              </div>
            </div>
          </el-card>
        </div>
      </main>
      <footer>
        <div style="padding: 1rem 0">
          <el-alert
            :closable="false"
            :title="$t('Funds.Inwardrem.TipsInfo1')"
            type="info"
            :description="$t('Funds.Inwardrem.TipsInfo2')"
            show-icon
          >
          </el-alert>
        </div>
      </footer>
    </div>
    <!-- 提交汇款证明弹窗 -->
    <el-dialog
      :show-close="false"
      :close-on-click-modal="false"
      title=""
      :visible.sync="visisub"
      width="520px"
    >
      <div slot="title">
        <el-alert
          :title="$t('Funds.Inwardrem.Tips')"
          type="warning"
          show-icon
        ></el-alert>
      </div>
      <div>
        <div>
          <div style="margin-bottom: 0.5rem">
            {{ $t("Funds.Inwardrem.AmountInUSD") }}
          </div>
          <CInput
            v-model="amt"
            type="number"
            min="1000"
            oninput="value=value.replace(/\D|^[0~999]/g,'')"
            :invalid-feedback="errorBackAmount"
              :is-valid="validatorAmount"
              valid-feedback=""
          />
          <!-- <CInput v-model="amt" type="number" oninput="if(value<1000)value=1000"  /> -->
        </div>
        <div>
          <div style="margin-bottom: 0.5rem">
            {{ $t("Funds.Inwardrem.RemittanceProof") }}
          </div>
          <el-upload
            class="upload-demo"
            ref="upload"
            action="/request/user.php?type=add_fund_account"
            :limit="1"
            :file-list="fileList"
            :auto-upload="false"
            :on-change="handleChange"
            accept=".jpg,.jpeg,.png,.gif,JPG,JPEG,PNG,GIF"
          >
            <el-button slot="trigger" size="small" type="primary">{{
              $t("Funds.Inwardrem.Upload")
            }}</el-button>
          </el-upload>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="clickCancel">{{
          $t("Funds.Inwardrem.TipsButton1")
        }}</el-button> -->
        <button style="width:70px;background:transparent;color:#333" @click="clickCancel" class="btn custer sbtn" type="button">{{ $t("Funds.Inwardrem.TipsButton1") }}</button>
        <!-- <el-button @click="subm" style="background: #00bea4; color: #fff">{{
          $t("Funds.Inwardrem.Summit")
        }}</el-button> -->
        <button style="width:70px;margin-left:.5rem" @click="subm" class="btn custer sbtn" type="button">{{ $t("Funds.Inwardrem.Summit") }}</button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getFundAccount, sumbmitDeposit } from "../../api/http";
export default {
  data() {
    return {
      funds_data: "",
      visisub: false,
      fileList: [],
      amt: null,
      remFile: "",
      errorBackAmount: "",
      flags: false
    };
  },
  created() {
    this.initData();
  },
  computed: {
    tsAddr() {
      return "G/F, The Center,99 Queen's Road Central, Central, Hong Kong"
    }
  },
  methods: {
    validatorAmount(val) {
      console.log("val==>", val);
      let reg = /^(\+)?\d+(\.\d+)?$/;
      if (val === null) {
        this.errorBackAmount = "";
        this.flags = false;
        return;
      }
      if(val < 1000)  {
        this.errorBackAmount = this.$t("Funds.Inwardrem.AmountInUSDTip");
        this.flags = true;
        return false;
      }
      if (reg.test(val)) {
        this.errorBackAmount = "";
        this.flags = false;
        return true;
      } 
      else {
        this.errorBackAmount = this.$t("AliPay.InputTip");
        this.flags = true;
        return false;
      }
    },
    showSubRetPf() {
      this.visisub = true;
    },
    // copy
    getCopy(data) {
      let input = document.createElement("input"); // js创建一个input输入框
      input.value = data; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input); // 将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        type: "success",
        message: "Copy Successful",
      });
    },
    // 上传文件的回调
    handleChange(file, fileList) {
      console.log("file", file, fileList);
      const isLt5M = file.size / 1024 / 1024 < 5;
      let typeArr = ["image/jpg", "image/png", "image/gif", "image/jpeg"];
      let isJPG = typeArr.indexOf(file.raw.type);
      if (isJPG === -1) {
        this.$message.error(
          `${this.$t("Funds.Accounts.UploadTip1")}jpg/png/jpeg/gif`
        );
        fileList.pop();
      }
      if (!isLt5M) {
        this.$message.error(`${this.$t("Funds.Accounts.UploadTip2")} 5MB!`);
        fileList.pop();
      }
      this.getBase64(file.raw).then((res) => {
        const params = res;
        this.remFile = params;
      });
      this.fileList = fileList;
    },
    clickCancel() {
      this.visisub = false;
      this.amt = null;
      this.remFile = "";
    },
    // 转Base64
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    // 提交账户证明
    subm() {
      if(this.amt == null || this.flags) {
        return
      }
      let obj = {
        type: "summit_deposit",
        uid: window.localStorage.getItem("uid"),
        amount: Number(this.amt),
        credential: this.remFile,
      };
      sumbmitDeposit(obj).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.visisub = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 初始化
    initData() {
      let data = {
        type: "get_fund_account",
        uid: window.localStorage.getItem("uid"),
      };
      getFundAccount(data).then((res) => {
        console.log("res==>", res);
        if (res.data.length == 0) {
          this.$confirm(
            this.$t("Funds.Inwardrem.TipsContent"),
            this.$t("Funds.Inwardrem.Tips"),
            {
              confirmButtonText: this.$t("Funds.Inwardrem.TipsButton2"),
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              type: "warning",
            }
          )
            .then(() => {
              this.$router.push("/funds/accounts");
            })
            .catch(() => {
              // this.$message.error(e)
            });
        }
        else if(res.data[0].status !== 'Complete') {
          this.$confirm(
            this.$t("Funds.Inwardrem.TipsContent2"),"",
            {
              confirmButtonText: this.$t("Funds.Inwardrem.TipsButton2"),
              showClose: false,
              showCancelButton: false,
              closeOnClickModal: false,
              type: "warning",
            }
          )
            .then(() => {
              this.$router.push("/funds/accounts");
            })
            .catch(() => {
            });
        }
        else {
          this.funds_data = res.data[0];
        }
      });
    },
  },
};
</script>
<style scoped>
.sbtn:hover{
  background: rgba(0,190,164, .8) !important;
}
.sbtn:focus{
  border-color: #00BEA4;
  box-shadow: 0 0 0 .25rem rgba(0,190,164, .5) !important;
}
.custer{
  background: #00bea4;
  color: #fff; 
  width: 30%;
  outline:0;
}
/*  */
.inward-box {
  padding-left: 2.6rem;
  background: #fff;
}
.warpper {
  width: 600px;
  margin: 0rem auto;
  padding-top: 0.5rem;
}
.iw-title {
  color: #3c2b2b;
  font-weight: bold;
  font-size: 20px;
}
.iw-dec {
  color: #93969d;
  font-size: 12px;
  /* padding: 0.88rem 0; */
  padding-top: 0.5rem;
}
.iw-amt-title {
  color: #3c2b2b;
  font-weight: bold;
  padding-top: 0.5rem;
}
.iw-amt-box {
  display: flex;
}
.amt-item {
  padding: 0 3rem;
  font-size: 12px;
}
.amt-title {
  color: #93969d;
}
.amt-info {
  font-weight: bold;
  color: #3c2b2b;
}
.main-box {
  padding-top: 0.88rem;
}
.main-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}
.m-item-key {
  padding-bottom: 0.25rem;
  font-weight: bold;
  color: #3c2b2b;
}
.m-item-val {
  padding-top: 0.25rem;
  color: #93969d;
  font-size: 14px;
}
</style>